import * as SecureStore from "expo-secure-store";
import React from "react";
import { Platform, RefreshControl, ScrollView } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import MyHeader from "./components/MyHeader";
import Routes from "./routes";
import useDoWeNeedMoreAddresses from "./useDoWeNeedMoreAddresses";
import utils from "./utils";
import { Home } from "./views/Home";
import Settings from "./views/Settings";
import SignIn from "./views/SignIn";
const RavencoinHandler = require("./_ravencoinhandler");

/*const mnemonicDefault =
  "natal hada sutil año sólido papel jamón combate aula flota ver esfera";*/

//natal hada sutil año sólido papel jamón combate aula flota ver esfera

//rice
//Keep track of address position.
//When we create new addresses
export let position = 0;
const privateKeys = {};

export default function AppWorker({ useBalance, startupMnemonic }) {
  const [route, setRoute] = React.useState(Routes.HOME);
  const [addresses, setAddresses] = React.useState([]);
  const [mnemonic, _setMnemonic] = React.useState(startupMnemonic);
  const [triggerUpdateDate, setTriggerUpdateDate] = React.useState(new Date());
  const [signInInfo, setSignInInfo] = React.useState({});

  const balanceInfo = useBalance(addresses, triggerUpdateDate);
  const doWeNeedMoreAddresses = useDoWeNeedMoreAddresses(
    addresses,
    balanceInfo
  );

  //"override" setMnemonic to store to secure store
  const setMnemonic = (data) => {
    const empty = [];
    position = 0;
    _setMnemonic(data);

    //On change mnemonic, clear addresses and trigger load balance
    setAddresses(empty);
    setTriggerUpdateDate(new Date());

    //If web
    if (Platform.OS === "web") {
      localStorage.setItem("mnemonic", data);
    } else if (Platform.OS !== "web") {
      SecureStore.setItemAsync("mnemonic", data);
    }
    //Route back to home
    setRoute(Routes.HOME);
  };
  //Every X minutes, run an update
  React.useEffect(() => {
    const update = () => {
      setTriggerUpdateDate(new Date());
    };
    const time = 1 * 60 * 1000;
    const i = setInterval(update, time);

    const cleanUp = () => {
      clearInterval(i);
    };

    return cleanUp;
  }, []);

  //Generate public addresses server side instead?
  //https://ethereum.stackexchange.com/questions/60139/getting-addresses-using-a-path-causes-the-error-could-not-derive-hardened-child

  //Good idea for balance/list of tokens/asset but does not give private key so still bad performance on IOS.

  //Server generates addresses and checks balances, and response back with path.
  //When we sign in, we check the path and generate the private key, should do.

  const mnemonicValid = mnemonic.length > 20;
  if (doWeNeedMoreAddresses && mnemonicValid === true) {
    const _addresses = [];
    const network = "rvn";
    const hdKey = RavencoinHandler.getHDKey(network, mnemonic);
    //const path = "m/44'/175'/0'/0/0";
    const INIT = Platform.OS === "web" ? 40 : 40;
    const TIMES = addresses.length === 0 ? INIT : 20;

    for (let i = 0; i < TIMES; i++) {
      //export function getAddressByPath(network: Network, hdKey: any, path: string): {

      const external = RavencoinHandler.getAddressByPath(
        network,
        hdKey,
        "m/44'/175'/0'/0/" + position
      );
      const internal = RavencoinHandler.getAddressByPath(
        network,
        hdKey,
        "m/44'/175'/0'/1/" + position
      );

      _addresses.push(external.address);
      _addresses.push(internal.address);

      privateKeys[external.address] = external.privateKey;
      privateKeys[internal.address] = internal.privateKey;

      position++;
    }

    const a = addresses.concat(_addresses);

    setAddresses(a);
  }

  //If no mnemonic force route to SETTINGS
  const _route = mnemonic.trim() ? route : Routes.SETTINGS;

  const selectUniqueAsset = (name) => {
    const n = { ...signInInfo };
    n.uniqueAsset = name;
    n.address = utils.getAddressByUniqueAsset(balanceInfo, name);
    n.privateKey = privateKeys[n.address];
    setSignInInfo(n);
    setRoute(Routes.SIGN_IN);
  };

  const onRefresh = () => {
    setTriggerUpdateDate(new Date());
  };
  const refreshing = (balanceInfo && balanceInfo.pendingUpdate) || false;

  return (
    <SafeAreaProvider>
      <MyHeader
        balanceInfo={balanceInfo}
        route={_route}
        setRoute={setRoute}
        setTriggerUpdateDate={setTriggerUpdateDate}
      />

      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {_route === Routes.SETTINGS && (
          <Settings setMnemonic={setMnemonic} mnemonic={mnemonic}></Settings>
        )}
        {_route === Routes.HOME && (
          <Home
            addresses={addresses}
            balanceInfo={balanceInfo}
            position={position}
            selectUniqueAsset={selectUniqueAsset}
            setTriggerUpdateDate={setTriggerUpdateDate}
          ></Home>
        )}

        {_route === Routes.SIGN_IN && (
          <SignIn
            balanceInfo={balanceInfo}
            signInInfo={signInInfo}
            onSignedIn={() => {
              setRoute(Routes.HOME);
            }}
          ></SignIn>
        )}
      </ScrollView>
    </SafeAreaProvider>
  );
}
