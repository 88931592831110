import React from "react";
import * as DataHandler from "./DataHandler";

let cacheTriggerDate = null;
export default function useBalance(addresses, triggerDate) {
  //Empty cache if new trigger date
  if (cacheTriggerDate !== triggerDate) {
    cacheTriggerDate = triggerDate;
  }

  const [balance, setBalance] = React.useState({});
  const [pendingUpdate, setPendingUpdate] = React.useState(false);
  const [updatedDate, setUpdatedDate] = new React.useState(new Date());
  React.useEffect(() => {
    //First off, filter out addresses not in cache
    try {
      setPendingUpdate(true);

      const promise = DataHandler.getBalance(addresses);

      promise.then((results) => {

        //Now all promises are done 
        setBalance(results);
        setPendingUpdate(false);
        setUpdatedDate(new Date());
      });

      promise.catch((e) => {
        console.dir(e);

        setPendingUpdate(false);
        alert("Something went wrong while updating");
      });
    } catch (e) {
      console.dir(e);
      setPendingUpdate(false);
      alert("Something went wrong while updating");
    }
  }, [addresses, triggerDate]);
  return {
    balance, //The current balance
    pendingUpdate,
    updatedDate,
    triggerDate, //The next time we should fetch balances
  };
}
