import React from "react";
import { Header, Text } from "react-native-elements";
import Routes from "../routes";
import theme from "../theme.json";
import { Icon } from "react-native-elements";
import { View } from "react-native";



import { ActivityIndicator } from 'react-native';


const iconByRoute = {
  SETTINGS: "home",
  HOME: "settings",
  SCANNER: "home",
  SIGN_IN: "home",
};

export default function MyHeader({
  balanceInfo,
  setRoute,
  route,
  setTriggerUpdateDate,
}) {
  const navigate = () => {
    if (route === Routes.HOME) {
      setRoute(Routes.SETTINGS);
    } else {
      setRoute(Routes.HOME);
    }
  };
  return (
    <Header
      elevation={1}
      backgroundColor={theme.palette.primaryColor}
      placement="left"
    >
      <Icon name={iconByRoute[route]} color="white" onPress={navigate} />
      <View>
        <Text elevation={10} style={{ color: "white", fontSize: 18 }}>
          Ravencoin authenticator
        </Text>
      </View>
      <UpdateIcon
        balanceInfo={balanceInfo}
        route={route}
        setTriggerUpdateDate={setTriggerUpdateDate}
      />
    </Header>
  );
}

function UpdateIcon({ balanceInfo, route, setTriggerUpdateDate }) {
  if (!balanceInfo) {
    return null;
  }

  if (balanceInfo.pendingUpdate === true) {
    return <ActivityIndicator color="#FFFFFF" />
  }
  //Do not show update icon if on settings page
  if (route === Routes.SETTINGS) {
    return null;
  }
  return (
    <Icon
      name="update"
      color="white"
      onPress={() => setTriggerUpdateDate(new Date())}
    ></Icon>
  );
}
