import * as React from "react";
import * as DataHandler from "./DataHandler";
const utils = require("./utils");

 
export default function useAssetMetaData(balance) {
  const [meta, setMeta] = React.useState({});

  //Morph unique assets and assets
  const assets = {
    ...utils.getUniqueAssets(balance),
    ...utils.getAssets(balance),
  };

  const assetNames = Object.keys(assets);
  if (!assetNames) {
    return null;
  }
  if (!assetNames.map) {
    return null;
  }
  assetNames.map((asset) => {
    //Check if we already have fetched meta data for this asset
    if (meta[asset]) {
      return;
    }
 
    //To prevent loading multiple times, add an empty object as meta, will be over written when ajax request is done
    meta[asset] = {};

    const promise = DataHandler.fetchMetaDataForAsset(asset).then((result) => {
      setMeta((currentMeta) => {
        const m = { ...currentMeta };
        m[asset] = result;
        return m;
      });
    });
  });

  return meta;
}
