import { View } from "react-native";
import React from "react";
import { Text } from "react-native-elements";
import { LinearProgress } from "react-native-elements";
import Spacer from "./Spacer";
import { Board } from "./Board";
const utils = require("../utils");
export default function Status({ balance }) {
  const values = utils.getUnconfirmed(balance);
  if (!values || values.length === 0) {
    return null;
  }
  return (
    <View>
      <Spacer />
      <Board>
        <Text h4>Unconfirmed transactions</Text>
        {values.map((v, index) => (
          <Text key={index}>{v}</Text>
        ))}
        <LinearProgress color="primary" />
      </Board>
      <Spacer />
    </View>
  );
}
