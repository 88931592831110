import { Button } from "react-native-elements";

//Import the general shadow style
import { shadowStyle } from "./shadowStyle";

const _shadowStyle = {
  ...shadowStyle,
  backgroundColor: "rgb(46, 62, 128)",
};

//For the Button container
const style = {
  padding: 10,
  minWidth: 120,
};
export default function (props) {
  const p = { ...props };
  p.style = style;
  if (p.buttonStyle) {
    p.buttonStyle = { ..._shadowStyle, ...p.buttonStyle };
  } else {
    p.buttonStyle = _shadowStyle;
  }
  return <Button {...p}></Button>;
}
