import Constants from "expo-constants";
import * as React from "react";
import { ActivityIndicator, Platform } from "react-native";
import AppWorker from "./AppWorker";
import useBalanceLive from "./useBalance";
import useBalanceMock from "./useBalanceMock";
import utils from "./utils";
import * as SecureStore from 'expo-secure-store';
import { View, Text } from "react-native";

import theme from "./theme.json";
export default function App() {


  const [startupMnemonic, setStartupMnemonic] = React.useState(utils.mnemonicDefault);
  const [loading, setLoading] = React.useState(true);

  //At startup check for saved mnemonic
  //Do NOT start the app until we have read mnemonic, on IOS this is an async operation

  //Also decide if we should use live data or mocked data (production or local development)
  React.useEffect(() => {

    if (Platform.OS === "web") {
      const m = localStorage.getItem("mnemonic");

      if (m && m.length > 10) {

        setStartupMnemonic(m);
      }
      setLoading(false);
    } else if (Platform.OS !== "web") {
      const promise = SecureStore.getItemAsync("mnemonic");
      promise.then((result) => {
        if (result) {
          setStartupMnemonic(result);
        }
        setLoading(false);
      });
    }
  }, []);


  if (loading === true) {
    return <View style={{ flex: 1 }}>
      <View style={{ flex: 1, backgroundColor: theme.palette.primaryColor }}></View>
      <View style={{ flex: 1, backgroundColor: theme.palette.primaryColor }} ><ActivityIndicator size="large" color="white" style={{ transform: [{ scale: 2 }] }} /></View>
      <View style={{ flex: 1, backgroundColor: theme.palette.primaryColor }}></View>                                                                                                                                                                                                      
    </View>
  }

  else if (loading === false) {

    if (Constants.debugMode === true) {
      return <AppWorker useBalance={useBalanceLive} startupMnemonic={startupMnemonic} />;
    } else if (Constants.debugMode == false) {
      return <AppWorker useBalance={useBalanceLive} startupMnemonic={startupMnemonic} />;
    }
  }

}
