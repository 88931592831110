import * as React from "react";

export default function useSecondsSinceLastUpdate(date) {
  const [tick, setTick] = React.useState(new Date());

  const [_interval, _setInterval] = React.useState(null);
  React.useEffect(() => {
    const work = () => {
      setTick(new Date());
    };

    if (_interval) {
      clearInterval(_interval);
    }
    const i = setInterval(work, 5000);
    _setInterval(i);

    return () => {
      clearInterval(i);
    };
  }, [date]);
  const now = Date.now();
  const lastUpdate = date.getTime();

  const result = Math.floor((now - lastUpdate) / 1000);
  return result;
}
