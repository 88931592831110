import * as React from "react";
import { View } from "react-native";

import { shadowStyle } from "./shadowStyle";
//There is another component called Card
//In React Native Elements, so we call this Board ;)

export function Board(props) {
  return <View style={shadowStyle}>{props.children}</View>;
}
