import React from "react";

const json = require("./mockdata/balance.json");

export default function useBalance(addresses) {
  //Convert date strings to Date

  json.updatedDate = new Date(json.updatedDate);
  json.triggerDate = new Date(json.triggerDate);

  return json;
}
