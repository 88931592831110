const ONE_HUNDRED_MILLION = 100000000;
function getBalance(balanceRaw) {
  if (!balanceRaw) {
    return 0;
  }
  const addresses = Object.keys(balanceRaw);

  let balance = 0;

  addresses.map((a) => {
    balance += parseFloat(balanceRaw[a].balance.confirmed);
  });

  return balance / 100000000;
}

function getUnconfirmed(balanceRaw) {
  const result = [];
  return result; /*
  const addresses = Object.keys(balanceRaw);

  
  addresses.map((address) => {
    const a = balanceRaw[address];
    if (a.balance.unconfirmed) {
      result.push("RVN " + a.balance.unconfirmed / 100000000);
    }

    const assets = Object.keys(a.assetBalances.unconfirmed);
    assets.map((asset) => {
      result.push(asset + " " + a.assetBalances.unconfirmed[asset] / 100000000);
    });
  });

  return result;*/
}

function getUniqueAssets(balanceInfo) {
  if (!balanceInfo) {
    return {};
  }
  if (!balanceInfo.balance) {
    return {};
  }
  const result = {};

  balanceInfo.balance.map(function (item) {
    if (item.assetName === "RVN") {
      return;
    }

    //Skip assets that does not contain hash
    if (item.assetName.indexOf("#") < 0) {
      return;
    }

    result[item.assetName] = item.balance / ONE_HUNDRED_MILLION;
  });
  return result;
}

//Find all assets that does not contain hash sign, they are unique
function getAssets(balanceInfo) {
  if (!balanceInfo) {
    return {};
  }

  if (!balanceInfo.balance) {
    return {};
  }
  const result = {};

  balanceInfo.balance.map(function (item) {
    const name = item.assetName;
    if (name === "RVN") {
      return;
    }
    result[item.assetName] = item.balance / ONE_HUNDRED_MILLION;
  });

  return result;
}
const mnemonicDefault = "           "; //eleven blank spaces

function getAddressByUniqueAsset(balanceInfo, uniqueAsset) {
  const addresses = Object.keys(balanceInfo.balance);

  for (const key of addresses) {
    const values = balanceInfo.balance[key];

    if (values.length === 0) {
      continue;
    }
    try {
      for (let value of values) {
        if (value.assetName === uniqueAsset) {
          return key;
        }
      }
    } catch (e) {}
  }
  return null;
}

function atob(input) {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  let str = input.replace(/=+$/, "");
  let output = "";

  if (str.length % 4 == 1) {
    throw new Error(
      "'atob' failed: The string to be decoded is not correctly encoded."
    );
  }
  for (
    let bc = 0, bs = 0, buffer, i = 0;
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = chars.indexOf(buffer);
  }

  return output;
}

module.exports = {
  atob,
  getAddressByUniqueAsset,
  getAssets,
  getBalance,
  getUnconfirmed,
  getUniqueAssets,
  mnemonicDefault,
  ONE_HUNDRED_MILLION,
};
