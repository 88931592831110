import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Button } from "react-native";
import { Platform } from "react-native";

//QR reader for web
import { QrReader } from "react-qr-reader";

//QR reader for Android/IOS
import { BarCodeScanner } from "expo-barcode-scanner";

function QR({ handleBarCodeScanned }) {
  const isWeb = Platform.OS === "web";

  if (isWeb === false) {
    return <SmartphoneQR handleBarCodeScanned={handleBarCodeScanned} />;
  }
  if (isWeb === true) {
    const obj = (
      <QrReader
        constraints={{
          facingMode: "environment",
        }}
        onResult={(result, error) => {
          if (!!result) {
            handleBarCodeScanned(result.text);
          }
          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: "100%" }}
      />
    );
    return obj;
  }

  return <Text>Only web supported right now</Text>;
}
export default function Scanner({ onSuccess }) {
  const [scanned, setScanned] = useState(false);
  const [data, setData] = React.useState(null);

  const handleBarCodeScanned = (data) => {
    //Check if data is text or json

    setScanned(true);
    setData(data);
    onSuccess(data);
  };

  return (
    <View style={styles.container}>
      <Text>Scanner</Text>
      <View style={{ flex: 1 }}>
        {scanned === false && (
          <QR handleBarCodeScanned={handleBarCodeScanned} />
        )}
      </View>
      <View>
        {scanned && (
          <Button
            title={"Tap to Scan Again"}
            onPress={() => setScanned(false)}
          />
        )}
        <Button onPress={() => onSuccess(data)} title="Back"></Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  scanner: {
    flex: 1,
  },
  container: {
    flexDirection: "column",
    minHeight: 300,
  },
});

function SmartphoneQR({ handleBarCodeScanned }) {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    };

    getBarCodeScannerPermissions();
  }, []);

  const onChange = ({ type, data }) => {
    console.log("Type and data", type, data);

    if (!data) {
      alert("No data");
      return;
    }
    setScanned(true);
    handleBarCodeScanned(data);
  };

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <View style={styles.container}>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : onChange}
        style={StyleSheet.absoluteFillObject}
      />
      {scanned && (
        <Button title={"Tap to Scan Again"} onPress={() => setScanned(false)} />
      )}
    </View>
  );
}
