import { View } from "react-native";
import { Text } from "react-native-elements";
import theme from "../theme.json";
import { AssetButton } from "./AssetButton";
import Spacer from "./Spacer";

export function UniqueAssets({
  selectUniqueAsset,
  uniqueAssets,

}) {
  if (!uniqueAssets || Object.keys(uniqueAssets).length === 0) {
    return (
      <View>
        <Text h4 style={{ color: theme.palette.primaryColor }}>
          My NFTs / Unique Assets
        </Text>
        <Spacer />
        <Text>None</Text>
      </View>
    );
  }
  const names = Object.keys(uniqueAssets).sort();
  return (
    <View>
      <Text h4 style={{ color: theme.palette.primaryColor, marginBottom: 10 }}>
        My NFTs / Unique Assets
      </Text>
      <Spacer />
      {names.map((name) => {
        return (
          <AssetButton
            key={name}
            name={name}
            onPress={() => {
              selectUniqueAsset(name);
              //createPressListener(meta[name])();
            }}
          ></AssetButton>
        );
      })}
    </View>
  );
}
