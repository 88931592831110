import * as Linking from "expo-linking";
import { Platform, View, TouchableOpacity} from "react-native";
import { Text } from "react-native-elements";
import theme from "../theme.json";
import useAssetMetaData from "../useAssetMetaData";
import { Board } from "./Board";
import Spacer from "./Spacer";
import { UniqueAssets } from "./UniqueAssets";

import { shadowStyle } from "./shadowStyle";
import AssetIcon from "./AssetIcon";
const style = { ...shadowStyle, backgroundColor: "rgb(46, 62, 128)" };

const utils = require("../utils");

export function AssetsList({ balance: balanceInfo, selectUniqueAsset }) {

  const nonUniqueAssets = utils.getAssets(balanceInfo);
  const uniqueAssets = utils.getUniqueAssets(balanceInfo);
  const meta = useAssetMetaData(balanceInfo);

  return (
    <View>
      <Board>
        <UniqueAssets
          selectUniqueAsset={selectUniqueAsset}
          uniqueAssets={uniqueAssets}
        />
      </Board>
      <Board>
        <Assets assets={nonUniqueAssets} meta={meta} />
      </Board>
    </View>
  );
}
export function createPressListener(assetMeta) {
  return function () {
    //Return a function that will handle on Press
    if (!assetMeta) {
      alert("Hmmm meta data for this token is not loaded yet");
      return;
    }

    if (!assetMeta.ipfs) {
      return;
    }
    const url = "https://gateway.ipfs.io/ipfs/" + assetMeta.ipfs;

    if (url) {
      if (Platform.OS === "web") {
        window.open(url, "_blank");
      } else {
        Linking.openURL(url);
      }
    }
  };
}

function Assets({ meta, assets }) {
  if (!assets || Object.keys(assets).length === 0) {
    return (
      <View>
        <Text h4 style={{ color: theme.palette.primaryColor }}>
          My Tokens
        </Text>
        <Spacer />
        <Text>None</Text>
      </View>
    );
  }
  const names = Object.keys(assets).sort();
  return (
    <View>
      <Text h4 style={{ color: theme.palette.primaryColor }}>
        My Tokens
      </Text>
      <Spacer />
      {names.map((name) => {
        const amount = assets[name];

        const split = name.split("/");

        let smallText = null;
        let largeText = null;

        if (split.length > 1) {
          smallText = split[0] + " /";
          largeText = split[1];
        } else if (split.length === 1) {
          largeText = split[0];
        }

        return (
          <View key={name}>
            <TouchableOpacity
              style={style}
              onPress={createPressListener(meta[name])}
            >
               <View>
                  <AssetIcon name={name} />
                </View>
              {smallText && (
                <Text style={{ marginBottom: 5, color: "white", fontSize: 13 }}>
                  {smallText}
                </Text>
              )}
              <View style={{ flex: 1, flexDirection: "row" }}>
               
                <View style={{ flex: 2 }}>
                  <Text
                    onPress={createPressListener(meta[name])}
                    style={{ color: "white", fontSize: 18 }}
                  >
                    {largeText}
                  </Text>
                </View>
               
                <View style={{ flex: 1 }}>
                  <Text
                    style={{ color: "white", textAlign: "right", fontSize: 18 }}
                  >
                    {amount.toLocaleString()}
                  </Text>
                </View>

              </View >

            </TouchableOpacity>
          </View>
        );
      })}
    </View>
  );
}




