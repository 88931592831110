import * as React from "react";
import { View } from "react-native";
import { Icon, Text } from "react-native-elements";
import theme from "../theme.json";
import SvgQRCode from "react-native-qrcode-svg";
import * as Clipboard from "expo-clipboard"; 
import { Board } from "./Board";

export default function Receive({ addresses, balance }) {
  if (!addresses || addresses.length < 1) {
    return null;
  }

  if (!balance) {
    return null;
  }
  if (Object.keys(balance).length === 0) {
    return null;
  }
  //Find an empty external (even) address with the lowest position.

  let address = null;
  for (let i = 0; i < addresses.length; (i += 2)) {
    let a = addresses[i];

    if (balance[a]) {

      if (balance[a].length === 0) {
        address = a;
        break
      }
    }

  }




  if (!address) {
    return null;
  }
  const copyToClipboard = () => {
    alert("Copied " + address);
    Clipboard.setString(address);
  };
  //Identify the first address we find that has no balance
  //Take "even" not "odd" addresses, "odd" are change addresses

  return (
    <Board>
      <View>
        <Text h4 style={{ color: theme.palette.primaryColor }}>
          Receive{" "}
          <Icon
            size={14}
            reverse
            name="file-copy"
            color={theme.palette.primaryColor}
            onPress={copyToClipboard}
          />
        </Text>
        <SvgQRCode value={address} />
      </View>
    </Board>
  );
}
