import axios from "axios";
import chunk from "lodash.chunk";

const BASE_URL = "https://rebel-balance-front.herokuapp.com";
export async function fetchMetaDataForAsset(assetName) {
  return fetchMetaDataForAssetLive(assetName);
}

export async function fetchMetaDataForAssetLive(assetName) {
  const promise = new Promise((resolve, reject) => {
    const encodedAssetName = encodeURIComponent(assetName);
    const promise = axios.get(
      BASE_URL + "/assetmetadata/" +
      encodedAssetName
    );

    promise.catch((e) => {
      reject(e);
    });

    promise.then((result) => {

      resolve(result.data);
    });
  });

  return promise;
}
export async function getBalance(addresses) {

  const url = BASE_URL + "/balance";

  if (!addresses) {
    return {};
  }
  if (addresses.length === 0) {
    return {};
  }


  try {
    const axiosResponse = await axios.post(url, { addresses });
    return axiosResponse.data;
  }
  catch (e) {
    throw new Error("Technical Error");
  }
}
export async function getBalanceOLD(_addresses) {
  const promise = new Promise((resolve, reject) => {
    try {
      const url = BASE_URL + "/addresses/";
      // const url = "http://localhost:3000/addresses";

      let chunks = [_addresses];
      //If loads of addresses, chunk em up
      if (_addresses.length > 50) {
        chunks = chunk(_addresses, 10);
      }

      const listOfPromises = chunks.map((c) => {
        const promise = axios.post(url, c);
        return promise;
      });

      const p = Promise.all(listOfPromises);
      p.catch((e) => {
        reject(e);
      });
      p.then((results) => {
        const value = {};
        //Now all promises are done
        results.map((response) => {
          const keys = Object.keys(response.data);

          keys.map((address) => {
            value[address] = response.data[address];
          });
        });

        resolve(value);
      });
    } catch (e) {
      reject(e);
    }
  });

  return promise;
}
