import * as React from "react";
import { View } from "react-native";

const LARGE = 40; 
const SMALL = 10;
export default function Spacer(props) {
  const style = {
    marginBottom: 20, //Default
  };
  if (props.large) {
    style.marginBottom = LARGE;
  }
  if(props.small){
    style.marginBottom =  SMALL;
  }
  return <View style={style}></View>;
}
